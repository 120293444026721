import { DEBUG } from '../../config';
import { getComplexObject } from '../../helper/complexObjectStorage';
import { getURLParams } from '../../helper/rtcFlowHandling';
import store from '../../redux/store';
import { UserTypeKeys, WebRtcObjectKeys } from '../../types/webRtc.types';

export const sendMessageToDispatcher = async data => {
    const { webRtcSessionId } = getURLParams();
    const privateChatThreadId = store.getState().webRtc.chatThreadSlice.privateChatThreadId;
    const privateChatThreadClient = getComplexObject(privateChatThreadId);

    if (privateChatThreadClient) {
        const message = {
            content: JSON.stringify({ user: UserTypeKeys.CALLER, deviceId: store.getState().authSessionSlice.authSessionId, webRtcSessionId, ...data }),
        };
        const options = { type: 'text' };
        const result = await privateChatThreadClient.sendMessage(message, options);

        if (result) {
            if (DEBUG) {
                console.log('sendPrivateMessage: ', result.id, JSON.parse(message.content));
            }
            return result;
        }
        
        
        return `'Failed to send message: ', ${message}`;
    }

};

export const sendMessageToChatThread = async data => {
    const { webRtcSessionId } = getURLParams();
    const storedSession = JSON.parse(sessionStorage.getItem(webRtcSessionId));
    const message = { content: JSON.stringify({ user: UserTypeKeys.CALLER, deviceId: storedSession.deviceId, webRtcSessionId, ...data }) };
    const options = { type: 'text' };
    const chatThreadClient = getComplexObject(WebRtcObjectKeys.CHAT_THREAD_CLIENT);
    const result = await chatThreadClient.sendMessage(message, options);

    if (DEBUG) {
        console.log('sendMessageToChatThread: ', result.id, message);
    }
};
