import React, { useState, useEffect } from 'react';
import { HIGHLIGHT_SYSTEM_PANEL_TIMEOUT } from '../../config';
import { replaceText } from '../../helper/helper';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import './InformationPanel.scss';

const SystemPanel: React.FC = () => {
    const texts = useSelector((state: RootState) => state.texts.texts);
    const activeDeviceId = useSelector((state: RootState) => state.sessionHandlingSlice.activeDeviceId);
    const devices = useSelector((state: RootState) => state.sessionHandlingSlice.devices);
    const language = useSelector((state: RootState) => state.session.language);

    const [systemPanelIsHighlighted, setSystemPanelIsHighlighted] = useState(false);
    const [operationSystem, setOperationSystem] = useState('info.notAvailable');
    const [browser, setBrowser] = useState('info.notAvailable');
    const [battery, setBattery] = useState('info.notAvailable');
    const [languageState, setLanguageState] = useState('info.notAvailable');
    const [connectionQuality, setConnectionQuality] = useState('info.notAvailable');

    useEffect(() => {
        if (activeDeviceId !== null) {
            setSystemPanelIsHighlighted(true)
            window.setTimeout(() => {
                setSystemPanelIsHighlighted(false);
            }, HIGHLIGHT_SYSTEM_PANEL_TIMEOUT);
        }
    }, [activeDeviceId]);

    useEffect(() => {
        const activeDevice = devices.find((device) => device.deviceId === activeDeviceId);

        if (activeDevice) {
            setOperationSystem(`${(activeDevice as any).systemInfo.osName} ${(activeDevice as any).systemInfo.osVersion}`);
            setBrowser((activeDevice as any).systemInfo.browser);
            setLanguageState(language);
            setBattery((activeDevice as any).systemInfo.battery);
        }
    }, [devices, activeDeviceId])

    const classes = `informationPanel ${systemPanelIsHighlighted ? 'informationPanel--is-highlighted' : ''}`;

    return (
        <ul className={classes}>
            <li className="informationPanel__item">
                <div className="itemWrapper">
                    <span>{replaceText(texts, 'info.os')}</span>
                </div>
                {replaceText(texts, operationSystem)}
            </li>
            <li className="informationPanel__item">
                <div className="itemWrapper">
                    <span>{replaceText(texts, 'info.browser')}</span>
                </div>
                {replaceText(texts, browser)}
            </li>
            <li className="informationPanel__item">
                <strong>
                    <span>{replaceText(texts, 'info.language')}</span>
                </strong>
                {replaceText(texts, `lang.${languageState}`)}
            </li>
            <li className="informationPanel__item">
                <strong>
                    <span>{replaceText(texts, 'info.battery')}</span>
                </strong>
                {replaceText(texts, battery)}
            </li>
            <li className="informationPanel__item">
                <strong>
                    <span>{replaceText(texts, 'info.upload')}</span>
                </strong>
                {replaceText(texts, connectionQuality)}
            </li>
        </ul>
    );
};

export default SystemPanel;
