import { clearGPSInterval, startGPSInterval } from '../../api/geplocationApi';
import { DEVICE_INFO_INTERVAL, FOCUS_FEATURE_TIMEOUT, SEND_CALLER_INFO_DELAY } from '../../config';
import { tabletPatternRegex } from '../../regex';
import { callerWebRTCManager } from '../webrtcManagers/CallerWebRTCManager';
import reduxStore from '../../redux/store';
import { dispatchActivateFocusControls, dispatchDeactivateFocusControls } from '../../redux/actions/focus';
import { runAfterTimeHasElapsed } from '../../helper/helper';
import { WebRtcMessageCaller } from '../../types';
import store from '../../redux/store';
import { setIsHeadMountedDisplayDevice } from '../../redux/slices/sessionHandlingSlice';
import { sendMessageToDispatcher } from '../actions/callerWebRtcActions';
import parse from 'platform';

/**
 * start sending gps coordinats
 */
export const sendCoords = () => {
    if (callerWebRTCManager.useGPS) {
        startGPSInterval();
    } else {
        clearGPSInterval();
    }
};

// UPDATED - UNTESTED
export const getAndSendBatteryInfo = () => {
    if ('getBattery' in navigator) {
        // TODO fix type
        (navigator as any).getBattery().then(battery => {
            var level = battery.level;

            sendMessageToDispatcher({
                type: WebRtcMessageCaller.BATTERY,
                level: level,
            });
        });
    } else {
        sendMessageToDispatcher({
            type: 'batteryError',
            message: 'get battery api not available',
        });
    }
};

// UPDATED - UNTESTED
export const sendIsHeadMountedDevice = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.IS_HEAD_MOUNTED_DEVICE,
    });
};

/**
 * start sending media infos
 */
export const sendMediaInfos = () => {
    callerWebRTCManager.mediaInterval = window.setInterval(() => {
        getMediaInfos();
    }, DEVICE_INFO_INTERVAL);
};

/**
 * get media infos - updated
 */
export const getMediaInfos = async () => {
    const videoDevices = await navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
            return devices.filter(device => device.kind === 'videoinput');
        })
        .catch(error => console.error('Error listing devices:', error));

    if (videoDevices) {
        const cams = Object.values(videoDevices).map(device => {
            return { label: device.label, id: device.deviceId };
        });

        if (cams && JSON.stringify(cams).length !== JSON.stringify(callerWebRTCManager.cams).length) {
            callerWebRTCManager.cams = cams;
            const message = { type: WebRtcMessageCaller.DEVICE_INFO, devices: cams };
            const response = await sendMessageToDispatcher(message).catch();
        }
    }
};

/**
 * detect head mounted display device
 */
export const detectHmdOrWearableDevice = () => {
    const scale = window.devicePixelRatio;

    if (navigator.userAgent.includes('glass') || navigator.userAgent.includes('oculus') || navigator.userAgent.includes('vive')) {
        sendIsHeadMountedDevice();
        store.dispatch(setIsHeadMountedDisplayDevice());
        return;
    }

    // TODO: fix typing
    if ((navigator as any).xr) {
        (navigator as any).xr
            .isSessionSupported('immersive-vr')
            .then(isSupported => {
                const userAgent = navigator.userAgent.toLowerCase();
                if (isSupported && scale < 2 && !tabletPatternRegex.test(userAgent)) {
                    sendIsHeadMountedDevice();
                    store.dispatch(setIsHeadMountedDisplayDevice());
                }
                return;
            })
            .catch(err => {
                console.error('Error checking HMD support:', err);
            });
    }
};

// TODO: find alternative for obtaining network information
/**
 * send connection infos
 */
export const sendConnectionInfos = () => {
    callerWebRTCManager.userAgent.fetchNetworkInformation().then(function (data) {
        const message = {
            data: WebRtcMessageCaller.NETWORK_INFO,
            testServer: data.testServer,
            httpPing: data.httpPing,
            upload: data.upload,
            download: data.download,
        };
        callerWebRTCManager.sendMessage(message);
    });
};

// UPDATED - UNTESTED
export const sendSystemInfo = async () => {
    const infos = parse.parse(navigator.userAgent);
    const data = {
        isAndroid: infos.os.toString().toLowerCase().indexOf('android') !== -1,
        isIOS: infos.os.toString().toLowerCase().indexOf('ios') !== -1,
        osMajorVersion: parseInt(infos.os.version.split('.')[0], 10),
        isFirefox: infos.name.toString().toLowerCase().indexOf('firefox') !== -1,
        osName: infos.os.family,
        osVersion: infos.os.version,
        browser: `${infos.name} - ${infos.version}`,
    };

    sendMessageToDispatcher({
        type: WebRtcMessageCaller.SYSTEM,
        ...data,
    });
};

// UPDATED - UNTESTED
export const sendCallerLanguage = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.UPDATE_CALLER_LANGUAGE,
        language: reduxStore.getState().texts.language,
    });
};

// UPDATED - UNTESTED
export const sendVideoNotAllowed = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.VIDEO_NOT_AVAILABLE,
    });
};

// UPDATED - UNTESTED
export const sendPhotoPermission = permission => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.PHOTO_PERMISSION,
        permission,
    });
};

// UPDATED - UNTESTED
export const sendStreamRecordingPermission = permission => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.STREAM_RECORDING_PERMISSION,
        permission,
    });
};

// UPDATED - UNTESTED
export const sendFileIsBusy = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.HD_FILE_CALLER_IS_BUSY,
    });
};

// UPDATED - UNTESTED
export const sendFileIsNotBusy = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.HD_FILE_CALLER_TRANSFER_ERROR,
    });
};

// UPDATED - UNTESTED
export const sendFileStart = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.HD_FILE_TRANSFER_STARTED,
    });
};

// UPDATED - UNTESTED
export const sendCallerPaintPoints = points => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.CALLER_PAINT_POINTS,
        points,
    });
};

// UPDATED - UNTESTED
export const sendAllowPaintingCaller = activeState => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.ALLOW_PAINTING,
        state: activeState,
    });
};

// UPDATED - UNTESTED
export const sendDeletePaintPointsCaller = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.DELETE_PAINT_POINTS_CALLER,
    });
};

// UPDATED - UNTESTED
export const sendToggleFeatureFocus = activeState => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.TOGGLE_FEATURE_FOCUS,
        state: activeState,
    });

    dispatchDeactivateFocusControls();
    runAfterTimeHasElapsed(dispatchActivateFocusControls, FOCUS_FEATURE_TIMEOUT);
};

// UPDATED - UNTESTED
export const declineDisclaimerAndEndCall = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.DECLINE_DISCLAIMER,
    });

    // TODO: update to ACS
    callerWebRTCManager.endCall();
};

// UPDATED - UNTESTED
export const acceptDisclaimerForCaller = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.ACCEPT_DISCLAIMER,
    });
};

// UPDATED - UNTESTED
export const disclaimerVisible = () => {
    sendMessageToDispatcher({
        type: WebRtcMessageCaller.CALLER_DISCLAIMER_VISIBLE,
    });
};

// send caller details only after disclaimer status is handled
export const sendCallerDetails = () => {
    sendMediaInfos();
    // sendConnectionInfos();
    getAndSendBatteryInfo();
    sendSystemInfo();
    sendCallerLanguage();
};
