import { ChatClient } from '@azure/communication-chat';
import reduxStore from '../../redux/store';
import store from '../../redux/store';
import { dispatchDisallowPhotoPermisison } from '../../redux/actions/session';
import {
    sendDispatcherLeftToCaller,
    sendHandoverSessionToCallerDevice,
} from '../outgoingMessages/outgoingMessagesDispatcher';
import {
    addSessionDevice,
    deactivateSessionHandover,
    deactivateSessionHijacking,
    replaceCallerDevice,
    unsetIsHeadMountedDisplayDevice,
    updateActiveDeviceId,
} from '../../redux/slices/sessionHandlingSlice';
import { createKpiLog } from '../../helper/helper';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { DEBUG, DISPLAY_ONLY_IN_SESSION } from '../../config';
import { WebRtcMessageDispatcher } from '../../types';
import { WebRtcObjectKeys, WebRtcUser } from '../../types/webRtc.types';
import { addComplexObject, getComplexObject } from '../../helper/complexObjectStorage';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { handleIncomingMessageDispatcher } from '../incomingMessages/incomingMessagesDispatcher';
import { createNewChatThread, establishConnectivityWithCallerDevice, sendPrivateMessage } from '../actions/dispatcherWebRtcActions';
import { setCommunicationUserId } from '../../redux/slices/webRtc/authSlice';

// event loader
export const loadMessageEventHandlingDispatcher = (chatClient: ChatClient) => {
    chatClient.on('chatMessageReceived', async e => {
        handleIncomingMessageDispatcher(e);
    });
    chatClient.on('realTimeNotificationConnected', () => {
        // communicationUserId is used to identify the dispatcher in chat threads
        const saveDispatcherCommunicationUserIdToRedux = async () => {
            const chatThreadClient = getComplexObject(WebRtcObjectKeys.CHAT_THREAD_CLIENT);
            const participants = chatThreadClient.listParticipants();
            for await (const participant of participants) {
                store.dispatch(setCommunicationUserId(participant.id.communicationUserId));
            }
        };

        saveDispatcherCommunicationUserIdToRedux();
    });
    chatClient.on('realTimeNotificationDisconnected', () => {
        if (DEBUG) {
            console.log('realTimeNotificationDisconnected');
        }
    });
    chatClient.on('participantsAdded', async e => {
        if (DEBUG) {
            console.log('participant added: ', e);
        }
    });
    chatClient.on('readReceiptReceived', e => {
        if (DEBUG) {
            console.log('readReceiptReceived: ', e);
        }
    });
};

export const handleCallerJoined = async data => {
    const callerDevice = {
        deviceId: data.message.deviceId.toString(),
        communicationUserId: data.sender.communicationUserId,
    };
    const noActiveCaller = reduxStore.getState().sessionHandlingSlice.activeDeviceId === null;
    const deviceIdExists = reduxStore.getState().sessionHandlingSlice.devices.some(device => device.deviceId === callerDevice.deviceId);
    const isActiveCallerDeviceRejoining = callerDevice.deviceId !== reduxStore.getState().sessionHandlingSlice.activeDeviceId;
    const sessionHandoverIsActive = reduxStore.getState().sessionHandlingSlice.sessionHandoverIsActive;
    const callerDisclaimerIsActive =
        reduxStore.getState().features.disclaimerFeatureCaller && reduxStore.getState().disclaimers.usageDisclaimerStateCaller !== 'none';
    const isHeadMountedDisplayDevice = reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice;
    const isNotConnectedOrHijackingIsActive =
        !reduxStore.getState().connection.isConnected || reduxStore.getState().sessionHandlingSlice.sessionHijackingIsActive;

    const privateChatThreadId = await createNewChatThread(callerDevice);

    if (noActiveCaller) {
        console.log('noActiveCaller: ', noActiveCaller);
        initializeCallerDevice(callerDevice);
        return;
    }

    if (sessionHandoverIsActive) {
        console.log('sessionHandoverIsActive: ', sessionHandoverIsActive);
        initializeSessionHandover(callerDevice);
        return;
    }

    if (deviceIdExists) {
        console.log('deviceIdExists: ', deviceIdExists);
        if (callerDisclaimerIsActive) {
            // TODO: add to web rtc actions
            // resetDispatcherDashboardStates();
        }

        if (isHeadMountedDisplayDevice) {
            store.dispatch(unsetIsHeadMountedDisplayDevice());
        }

        if (!isActiveCallerDeviceRejoining) {
            initializeRejoiningDevice(callerDevice);
        }
        return;
    }

    // new caller device attempts to join
    if (isNotConnectedOrHijackingIsActive) {
        console.log('isNotConnectedOrHijackingIsActive: ', isNotConnectedOrHijackingIsActive);
        replaceCurrentCallerDevice(callerDevice);

        return;
    }

    console.log('sendDispatcherLeft:');
    // send disconnect message to new device attempting to connect
    // TODO: this can be removed after refactoring incomingMessagesDispatcher
    await sendPrivateMessage({ type: WebRtcMessageDispatcher.DISPATCHER_LEFT }, privateChatThreadId);
};

const initializeCallerDevice = async (callerDevice: WebRtcUser) => {
    const privateChatThreadId = await createNewChatThread(callerDevice);
    dispatchDisallowPhotoPermisison();
    await sendPrivateMessage({ type: WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED }, privateChatThreadId);
    store.dispatch(addSessionDevice({ ...callerDevice, privateChatThreadId }));
    store.dispatch(updateActiveDeviceId(callerDevice));
    establishConnectivityWithCallerDevice();
};

const initializeSessionHandover = async (callerDevice: WebRtcUser) => {
    const callerDisclaimerIsActive =
        reduxStore.getState().features.disclaimerFeatureCaller && reduxStore.getState().disclaimers.usageDisclaimerStateCaller !== 'none';
    const isHeadMountedDisplayDevice = reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice;
    const deviceIdExists = reduxStore.getState().sessionHandlingSlice.devices.some(device => device.deviceId === callerDevice.deviceId);
    const privateChatThreadId = await createNewChatThread(callerDevice);

    if (callerDisclaimerIsActive) {
        // TODO: add to web rtc actions
        // resetDispatcherDashboardStates();
    }

    if (isHeadMountedDisplayDevice) {
        store.dispatch(unsetIsHeadMountedDisplayDevice());
    }

    // send session handover success message to active callerDevice
    // TODO: add to web rtc actions
    // sendHandoverSessionToCallerDevice();

    store.dispatch(updateActiveDeviceId(callerDevice.deviceId));
    dispatchDisallowPhotoPermisison();

    if (!deviceIdExists) {
        store.dispatch(addSessionDevice({ ...callerDevice, privateChatThreadId }));
    }

    await sendPrivateMessage(WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED, privateChatThreadId);
    store.dispatch(deactivateSessionHandover());
    establishConnectivityWithCallerDevice();
    createKpiLog('sessionHandoverSuccessful', '', { 0: callerDevice.deviceId });

    // TODO: add to web rtc actions
    // dispatcherWebRTCManager.toggleOffAllFeatures();

    addNotificationAndShowDispatch('session.handover.success', 'info', DISPLAY_ONLY_IN_SESSION);
    store.dispatch(deactivateSessionHijacking());
};

const initializeRejoiningDevice = async (callerDevice: WebRtcUser) => {
    const privateChatThreadId = await createNewChatThread(callerDevice);
    // send session handover success message to active callerDevice
    // TODO: replace with sendPrivateMessage
    sendHandoverSessionToCallerDevice();
    store.dispatch(updateActiveDeviceId(callerDevice.deviceId));
    dispatchDisallowPhotoPermisison();
    await sendPrivateMessage(WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED, privateChatThreadId);
    establishConnectivityWithCallerDevice();
    createKpiLog('callerDeviceChanged', '', { 0: callerDevice.deviceId });
    addNotificationAndShowDispatch('session.device.changed', 'info', DISPLAY_ONLY_IN_SESSION);

    // TODO: add to web rtc actions
    // dispatcherWebRTCManager.toggleOffAllFeatures();

    store.dispatch(deactivateSessionHijacking());
};

const replaceCurrentCallerDevice = async (callerDevice: WebRtcUser) => {
    const currentCallerDevice = reduxStore
        .getState()
        .sessionHandlingSlice.devices.find(device => device.deviceId === reduxStore.getState().sessionHandlingSlice.activeDeviceId);
    const callerDisclaimerIsActive =
        reduxStore.getState().features.disclaimerFeatureCaller && reduxStore.getState().disclaimers.usageDisclaimerStateCaller !== 'none';
    const isHeadMountedDisplayDevice = reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice;
    const privateChatThreadId = await createNewChatThread(callerDevice);

    if (callerDisclaimerIsActive) {
        // TODO: add to web rtc actions
        // resetDispatcherDashboardStates();
    }

    if (isHeadMountedDisplayDevice) {
        store.dispatch(unsetIsHeadMountedDisplayDevice());
    }

    // send disconnect message to current caller device and remove from device list
    // TODO: replace with sendPrivateMessage
    sendDispatcherLeftToCaller();

    // replace current caller device with joining user device
    store.dispatch(replaceCallerDevice({ currentDevice: currentCallerDevice, newDevice: callerDevice }));
    store.dispatch(updateActiveDeviceId(callerDevice));
    dispatchDisallowPhotoPermisison();
    await sendPrivateMessage(WebRtcMessageDispatcher.HANDOVER_SESSION, privateChatThreadId);
    establishConnectivityWithCallerDevice();

    // TODO: add to web rtc actions
    // dispatcherWebRTCManager.toggleOffAllFeatures();

    store.dispatch(deactivateSessionHijacking());
};

export const saveSessionCredential = token => {
    const webRtcSessionId = store.getState().webRtc.authSlice.webRtcSessionId;

    if (webRtcSessionId) {
        let credential = new AzureCommunicationTokenCredential(token);

        if (credential) {
            addComplexObject(WebRtcObjectKeys.TOKEN_CREDENTIAL, credential);
        }
    }
};
