import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    token: string | null;
    communicationUserId: string | null;
    webRtcSessionId: string | null;
}

const initialState: AuthState = {
    token: null,
    communicationUserId: null,
    webRtcSessionId: null,
};

const authSlice = createSlice({
    name: 'webRtc/authSlice',
    initialState,
    reducers: {
        setWebRtcToken(state, action: PayloadAction<any>) {
            state.token = action.payload;
        },
        setWebRtcSession(state, action: PayloadAction<any>) {
            state.webRtcSessionId = action.payload;
        },
        setCommunicationUserId(state, action: PayloadAction<any>) {
            state.communicationUserId = action.payload;
        },
        resetAuthSlice() {
            return initialState;
        },
    },
});

export const { setWebRtcToken, setWebRtcSession, setCommunicationUserId, resetAuthSlice } = authSlice.actions;

export default authSlice.reducer;
