import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';

import LogoutButton from './LogoutButton';
import Loading from '../Globals/Loading';
import SystemMessages from './SystemMessages';
import SystemFooter from '../Globals/SystemFooter';
import LanguageSelect from '../Globals/LanguageSelect';

import {
    getTitle,
    getLogo,
    isValidPhone,
    replaceText,
    setDispatcherLanguage,
    isOnStartPage,
    getPasswordExpirationTime,
    normalizePhone,
} from '../../helper/helper';
import { errorLog } from '../../helper/logging';
import { BACKEND_POLLING_TIMEOUT, DEBUG, PLACEHOLDER_COUNTY_CODE, POLLING_INTERVAL } from '../../config';

import {
    getPhoneNumber,
    phoneNumberConsumed,
    getApplicationFeaturesDispatcher,
    getContactsDispatcher,
    getCurrentDispatchCenterConfig,
    getImage,
} from '../../api/backendApi';

import './StartDashboard.scss';
import { addLogDispatch } from '../../redux/actions/logs';
import { enableFeaturesDispatch } from '../../redux/actions/features';

import SessionDownload from './SessionDownload';
import { setPhonenumbersDispatch } from '../../redux/actions/phonenumbers';
import { dispatchAddWhitelabelLogo } from '../../redux/actions/session';
import { PhoneCountry } from '../Globals/PhoneCountry';
import { Phonebook } from '../Dispatcher/Phonebook';
import { hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import DisclaimerPanel from '../Globals/DisclaimerPanel';
import { dispatchActivatePhotoAutoDownload, dispatchCallerPageNotLoaded, dispatchDeactivatePhotoAutoDownload } from '../../redux/actions/application';
import { getPasswordExpiration } from '../../api/passwordApi';
import { dispatcherAuthManager } from '../../store/DispatcherAuthManager';
import { resetPermissionsDispatch } from '../../redux/actions/permissions';
import store, { RootState } from '../../redux/store';
import { systemConfig } from '../../helper/systemConfigBuilder';
import { imageEndpointType } from '../../types';
import { resetChatThreadSlice } from '../../redux/slices/webRtc/chatThreadSlice';
import { resetRoomSlice } from '../../redux/slices/webRtc/roomSlice';
import { setWebRtcSession, setWebRtcToken } from '../../redux/slices/webRtc/authSlice';

const showSMSCheckbox = systemConfig.REACT_APP_SHOW_SMS_CHECKBOX === true;
const sendSMS = systemConfig.REACT_APP_SEND_SMS === true;

const StartDashboard: React.FC = () => {
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordWarningDisplayed, setPasswordWarningDisplayed] = useState(false);
    const [sendSMSState, setSendSMSState] = useState(sendSMS);

    const history = useHistory();

    const usageDisclaimerStateDispatcher = useSelector((state: RootState) => state.disclaimers.usageDisclaimerStateDispatcher);
    const sessionDownloadFeature = useSelector((state: RootState) => state.features.sessionDownloadFeature);
    const texts = useSelector((state: RootState) => state.texts.texts);
    const phonebookFeature = useSelector((state: RootState) => state.features.phonebookFeature);
    const passwordChangeSent = useSelector((state: RootState) => state.application.passwordChangeSent);
    const authSessionId = useSelector((state: RootState) => state.authSessionSlice.authSessionId);

    let _pollIntervalRef = useRef<number | null>(null);
    let _pollTimeoutRef = useRef<number | null>(null);

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        dispatcherAuthManager.phone = phone;

        // reset web rtc session id
        startSessionInitializationProcess();
    };

    const backendServiceUnavailable = () => {
        errorLog({
            message: 'Error Backend-Service',
            error: 'Error backend service unavailable',
            eventId: 'ERROR_BACKEND_SERVICE',
        });
        window.setTimeout(() => {
            window.location.href = '/error.html?error=Error-Backend-Service';
        }, 100);
    };

    const pollAPI = async () => {
        clearPolling();

        // check backend availability
        const backendServiceTimeout = window.setTimeout(() => {
            backendServiceUnavailable();
        }, BACKEND_POLLING_TIMEOUT);

        try {
            const response = await getPhoneNumber();
            clearTimeout(backendServiceTimeout);
            if (response?.phoneNumber && response?.bystanderToken) {
                dispatcherAuthManager.sessionId = response.bystanderToken;

                if (DEBUG) addLogDispatch(['polling api ended']);

                const formattedPhone = normalizePhone(response.phoneNumber);
                await phoneNumberConsumed(formattedPhone.toString());
            }
        } catch (e) {
            clearTimeout(backendServiceTimeout);
            clearPolling();
            return;
        }

        _pollIntervalRef.current = window.setInterval(pollAPI, POLLING_INTERVAL);
    };

    const startPolling = () => {
        clearPolling();
        if (DEBUG) addLogDispatch(['polling api started']);
        pollAPI();
    };

    const clearPolling = () => {
        clearInterval(_pollIntervalRef.current);
    };

    const validateForm = () => {
        return isValidPhone(phone);
    };

    const startSessionInitializationProcess = async () => {
        let data;
        try {
            // create caller (bystander) token in backend
            if (!store.getState().authSessionSlice.isPhoneNumberConsumed) {
                data = await dispatcherAuthManager.createBystanderAndWebRtcProperties(phone);
            }

            if (!data) {
                setIsLoading(false);
                return;
            }

            // send sms to caller
            const sms = await dispatcherAuthManager.sendSMS(!sendSMS, false).catch(e => {
                setIsLoading(false);

                // TODO: StartDashboard: replace these with redux states
                dispatcherWebRTCManager.connectedSession = null;
                dispatcherWebRTCManager.connected = false;
            });

            if (sms.url.indexOf('null') !== -1 || sms.url.indexOf('undefined') !== -1) {
                setIsLoading(false);
                return;
            }


            if (_pollIntervalRef) {
                clearPolling();
                if (DEBUG) addLogDispatch(['polling api ended']);
            }

            // clear all notifications
            hideAndRemoveNotificationsDispatch('error');
            hideAndRemoveNotificationsDispatch('info');
            hideAndRemoveNotificationsDispatch('warning');

            dispatcherWebRTCManager.connectedSession = true;

            // proceed to dispatcher dashboard
            history.push(`/disptchr/${authSessionId}/${data.token}`);
        } catch (e) {
            errorLog({
                message: 'error while handling phone number',
                error: e,
                eventId: 'HANDLE_PHONE_NUMBER',
            });
        }
    };

    // get enabled feature list from backend
    useEffect(() => {
        const fetchApplicationFeaturesDispatcher = async () => {
            enableFeaturesDispatch(await getApplicationFeaturesDispatcher());
        };

        fetchApplicationFeaturesDispatcher();
    }, []);

    useEffect(() => {
        const fetchLogo = async () => {
            const whitelabelLogo = await getImage({ type: 'base64', imageType: imageEndpointType.LOGO });
            if (whitelabelLogo) {
                dispatchAddWhitelabelLogo({ logo: whitelabelLogo });
            }
        };

        fetchLogo();
    }, []);

    // get preset language from backend and initialize texts
    useEffect(() => {
        setDispatcherLanguage();
    }, []);

    // reset caller permission states
    useEffect(() => {
        resetPermissionsDispatch();
    }, []);

    // reset caller page loaded state to false
    useEffect(() => {
        dispatchCallerPageNotLoaded();
    }, []);

    // reset webRtc states
    useEffect(() => {
        store.dispatch(resetChatThreadSlice());
        store.dispatch(resetRoomSlice());
        store.dispatch(setWebRtcToken(null));
        store.dispatch(setWebRtcSession(null));
    }, []);

    // backend polling for phone number consumed
    useEffect(() => {
        startPolling();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: StartDashboard: move this to snapshot gallery
    useEffect(() => {
        const fetchDispatchCenterConfig = async () => {
            const dispatchCenterConfig = await getCurrentDispatchCenterConfig();
            if (dispatchCenterConfig) {
                if (dispatchCenterConfig.preventAutoDownload && dispatchCenterConfig.preventAutoDownload === true) {
                    dispatchDeactivatePhotoAutoDownload();
                } else {
                    dispatchActivatePhotoAutoDownload();
                }
            }
        };

        fetchDispatchCenterConfig();
    }, []);

    useEffect(() => {
        const fetchDispatcherContacts = async () => {
            setPhonenumbersDispatch(await getContactsDispatcher());
        };

        if (phonebookFeature) {
            fetchDispatcherContacts();
        }
    }, [phonebookFeature]);

    useEffect(() => {
        const fetchPasswordExpiration = async () => {
            const passwordExpiration = await getPasswordExpiration();
            getPasswordExpirationTime(passwordExpiration);
            setPasswordWarningDisplayed(true);
        };

        if (Object.keys(texts).length !== 0 && isOnStartPage() && !passwordChangeSent && !passwordWarningDisplayed) {
            fetchPasswordExpiration();
        } else {
            hideAndRemoveNotificationsDispatch('pending');
        }
    }, [passwordChangeSent, passwordWarningDisplayed, texts]);

    useEffect(() => {
        const pollTimeoutId = _pollTimeoutRef.current;
        return () => {
            clearTimeout(pollTimeoutId);
            clearPolling();
        };
    }, []);

    return (
        <div className="startDashboard">
            <div className="navbar">
                <div className="logo">
                    <img src={getLogo()} alt="logo" />
                    <span dangerouslySetInnerHTML={{ __html: getTitle() }}></span>
                </div>
                <div>
                    <LanguageSelect />
                    <LogoutButton beforeLogout={clearPolling} />
                </div>
            </div>

            <div className="Login">
                {sessionDownloadFeature && <SessionDownload />}
                <DisclaimerPanel user="dispatcher" />

                {usageDisclaimerStateDispatcher === 'accepted' || usageDisclaimerStateDispatcher === 'none' ? (
                    <form onSubmit={handleSubmit}>
                        <div className="form__group">
                            <label>
                                <span>{replaceText(texts, 'new.phone')}</span>
                                <PhoneCountry phone={phone} />
                            </label>
                            <input
                                id="phone"
                                type="phone"
                                placeholder={`+${PLACEHOLDER_COUNTY_CODE}...`}
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                            />
                        </div>

                        {showSMSCheckbox && (
                            <div className="form__group">
                                <label>
                                    <input type="checkbox" id="sendSMS" checked={sendSMSState} onChange={e => setSendSMSState(e.target.checked)} />
                                    {replaceText(texts, 'new.sendsms')}
                                </label>
                            </div>
                        )}

                        {isLoading ? (
                            <Loading text={replaceText(texts, 'sms.sending')} />
                        ) : (
                            <button id="start" className="btn btn--primary btn--full" disabled={!validateForm()} type="submit">
                                {replaceText(texts, 'new.button')}
                            </button>
                        )}

                        {phonebookFeature && <Phonebook clickHandler={setPhone} showName />}
                    </form>
                ) : (
                    <Loading text={replaceText(texts, 'application.loading')} />
                )}
            </div>

            <SystemMessages />
            <SystemFooter />
        </div>
    );
};

export default StartDashboard;
