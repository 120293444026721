import { sendFileTransferInfo } from '../../api/backendApi';
import {
    DEBUG,
    DISPLAY_ONLY_IN_SESSION,
    FOCUS_FEATURE_TIMEOUT,
    MAX_ALLOWED_CONFERENCE_USERS,
    SESSION_HIJACKING_EXPIRATION_TIMEOUT,
    VIDEO_AUTO_PLAY_REJECTION_TIMEOUT,
} from '../../config';
import { createKpiLog, formatDataSize, getFileTypeCategory, isOnStartPage, runAfterTimeHasElapsed } from '../../helper/helper';
import { getURLParams } from '../../helper/rtcFlowHandling';
import {
    activateChatDispatcherDispatch,
    activateVideoDispatcherDispatch,
    deactivateAudioStreamDispatcherDispatch,
    deactivateDrawDispatcherDispatch,
    deactivateVideoDispatcherDispatch,
    dispatchCallerFileIsBusy,
    dispatchCallerFileIsNotBusy,
    dispatchCallerFileTransferStarted,
    dispatchCallerPageLoaded,
    dispatchUnsetLiveVideoIsLoading,
    muteCallerMicrophoneDispatcherDispatch,
    setCallerTabInFocusDispatch,
    setCallerTabNotInFocusDispatch,
    unmuteCallerMicrophoneDispatcherDispatch,
} from '../../redux/actions/application';
import { changeUsageDisclaimerStateCallerDispatch } from '../../redux/actions/disclaimers';
import { addFileCallerDispatch } from '../../redux/actions/files';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import { dispatchAddPointsCaller, dispatchAllowPainting, dispatchDeletePaintCaller, dispatchDisallowPainting } from '../../redux/actions/paint';
import {
    addSessionInfosDispatch,
    dispatchAddSessionFile,
    dispatchAllowPhotoPermisison,
    dispatchDisallowPhotoPermisison,
    updatePhonenumberDispatch,
} from '../../redux/actions/session';
import reduxStore from '../../redux/store/index';
import { dispatcherWebRTCManager } from '../webrtcManagers/DispatcherWebRTCManager';
import { WebRtcManagerType, WebRtcMessageCaller, WebRtcMessageConference, WebRtcMessageDispatcher } from '../../types';
import {
    dispatchSetFocusTriggeredByCaller,
    dispatchSetFocusWindowBidi,
    dispatchSetFocusWindowChat,
    dispatchSetFocusWindowExternalStream,
    dispatchSetFocusWindowLiveVideo,
    dispatchSetFocusWindowScreenshare,
    dispatchUnsetFocusChangedByCaller,
} from '../../redux/actions/focus';
import {
    dispatchAddConnectionQuality,
    dispatchAddInvitedUser,
    dispatchSetAudioError,
    dispatchSetIsLeaving,
    dispatchToggleAudioMuted,
} from '../../redux/actions/invitedUsers';
import {
    sendAllowPaintingToConferenceUsers,
    sendApplicationData,
    sendAudioActivationStatusFromStore,
    sendCurrentFocusFeatureStatus,
    sendWebRtcMessage,
    sendSessionData,
    sendToggleChat,
    sendToggleExternalStreaming,
} from '../outgoingMessages/outgoingMessagesDispatcher';
import { FOCUS_FEATURE_TYPE } from '../../types/focus.types';
import store from '../../redux/store/index';
import {
    activateSessionHijacking,
    addBatteryInfo,
    addDeviceSystemInfo,
    deactivateSessionHijacking,
    setIsHeadMountedDisplayDevice,
    updateKnownSmartConnectDevice,
} from '../../redux/slices/sessionHandlingSlice';
import { serializeContact } from '../../redux/utils/serializeContact';
import { dispatcherAuthManager } from '../../store/DispatcherAuthManager';
import { SmartConnectDeviceType } from '../../types/sessionHandling.types';
import { denyStreamRecordingPermissionDispatch, grantStreamRecordingPermissionDispatch } from '../../redux/actions/permissions';
import { UserTypeKeys } from '../../types/webRtc.types';
import { handleCallerJoined } from '../events/dispatcherIncomingEvents';
import { createNewChatThread, sendPrivateMessage } from '../actions/dispatcherWebRtcActions';

export const handleIncomingMessageDispatcher = async e => {
    const sender = e.sender;
    const message = JSON.parse(e.message);

    if (message?.user !== UserTypeKeys.DISPATCHER) {
        if (DEBUG) {
            console.log('handleIncomingMessageDispatcher: ', { sender: sender, message: message });
        }

        const { dispatcherAuthSessionId } = getURLParams();

        /**
         * INCOMING CALLER MESSAGES
         **/

        if (message.user === UserTypeKeys.CALLER) {
            const noActiveCallerDevice = reduxStore.getState().sessionHandlingSlice.activeDeviceId === null;
            const isFromActiveDevice = message.deviceId.toString() === reduxStore.getState().sessionHandlingSlice.activeDeviceId;
            const sessionHijackingIsActive = reduxStore.getState().sessionHandlingSlice.sessionHijackingIsActive;
            const sessionHandoverIsActive = reduxStore.getState().sessionHandlingSlice.sessionHandoverIsActive;

            if (noActiveCallerDevice || isFromActiveDevice || sessionHijackingIsActive || sessionHandoverIsActive) {
                switch (message.type) {
                    case WebRtcMessageCaller.SYSTEM: {
                        dispatcherWebRTCManager.isAndroid = message.isAndroid;
                        dispatcherWebRTCManager.isIOS = message.isIOS;
                        dispatcherWebRTCManager.osMajorVersion = message.osMajorVersion;
                        dispatcherWebRTCManager.isFirefox = message.isFirefox;

                        store.dispatch(
                            addDeviceSystemInfo({
                                activeDeviceId: store.getState().sessionHandlingSlice.activeDeviceId,
                                osName: message.osName,
                                osVersion: message.osVersion,
                                browser: message.browser,
                            })
                        );

                        addSessionInfosDispatch({
                            osName: message.osName,
                            osVersion: message.osVersion,
                            browser: message.browser,
                        });

                        if (DEBUG)
                            console.log(
                                `OS Infos - android: ${dispatcherWebRTCManager.isAndroid} - iOS: ${dispatcherWebRTCManager.isIOS} - version: ${dispatcherWebRTCManager.osMajorVersion}`
                            );
                        break;
                    }

                    case WebRtcMessageCaller.BATTERY: {
                        const battery = `${Math.round(message.level * 100)}%`;
                        store.dispatch(
                            addBatteryInfo({
                                activeDeviceId: store.getState().sessionHandlingSlice.activeDeviceId,
                                battery,
                            })
                        );

                        createKpiLog('infoBattery', '', {
                            0: battery,
                        });

                        addSessionInfosDispatch({
                            osName: message.osName,
                            osVersion: message.osVersion,
                            browser: message.browser,
                        });

                        if (DEBUG)
                            console.log(
                                `OS Infos - android: ${dispatcherWebRTCManager.isAndroid} - iOS: ${dispatcherWebRTCManager.isIOS} - version: ${dispatcherWebRTCManager.osMajorVersion}`
                            );
                        break;
                    }

                    case WebRtcMessageCaller.HEARTBEAT_PONG:
                        dispatcherWebRTCManager.pongMissed = 0;
                        dispatcherWebRTCManager.pongAccepted += 1;
                        break;

                    case WebRtcMessageCaller.HD_FILE_CALLER_IS_BUSY:
                        dispatchCallerFileIsBusy();
                        break;

                    case WebRtcMessageCaller.HD_FILE_CALLER_TRANSFER_ERROR:
                        dispatchCallerFileIsNotBusy();
                        break;

                    case WebRtcMessageCaller.HD_FILE_TRANSFER_STARTED:
                        dispatchCallerFileTransferStarted();
                        break;

                    case WebRtcMessageCaller.CALLER_PAINT_POINTS:
                        dispatchAddPointsCaller(message.points);
                        break;

                    case WebRtcMessageCaller.ALLOW_PAINTING:
                        message.state ? dispatchAllowPainting() : dispatchDisallowPainting();
                        break;

                    case WebRtcMessageCaller.DELETE_PAINT_POINTS_CALLER:
                        dispatchDeletePaintCaller();
                        break;

                    case WebRtcMessageCaller.DECLINE_DISCLAIMER:
                        changeUsageDisclaimerStateCallerDispatch('declined');
                        hideAndRemoveNotificationsDispatch('pending');
                        addNotificationAndShowDispatch('disclaimer.declined.caller', 'error', DISPLAY_ONLY_IN_SESSION);
                        createKpiLog('stateCallerDisclaimer', 'declined');
                        break;

                    case WebRtcMessageCaller.ACCEPT_DISCLAIMER:
                        changeUsageDisclaimerStateCallerDispatch('accepted');
                        hideAndRemoveNotificationsDispatch('pending');
                        updatePhonenumberDispatch(dispatcherAuthManager.phone);
                        createKpiLog('stateCallerDisclaimer', 'accepted');
                        break;

                    case WebRtcMessageCaller.PHOTO_PERMISSION:
                        message.permission ? dispatchAllowPhotoPermisison() : dispatchDisallowPhotoPermisison(true);
                        break;

                    case WebRtcMessageCaller.STREAM_RECORDING_PERMISSION:
                        if (message.permission) {
                            grantStreamRecordingPermissionDispatch();
                        } else {
                            denyStreamRecordingPermissionDispatch(true);
                        }
                        break;

                    case WebRtcMessageCaller.REQUEST_STREAM_RETRY:
                        // Retry stream capture if promise doesn't resolve on caller side
                        if (dispatcherWebRTCManager.streamRetryRequests < 1) {
                            setTimeout(() => {
                                activateVideoDispatcherDispatch();
                            }, VIDEO_AUTO_PLAY_REJECTION_TIMEOUT);
                            dispatcherWebRTCManager.streamRetryRequests = 1;
                        } else if (dispatcherWebRTCManager.streamRetryRequests === 1) {
                            addNotificationAndShowDispatch('camera.error', 'error', DISPLAY_ONLY_IN_SESSION);
                            dispatchUnsetLiveVideoIsLoading();
                        }
                        break;

                    // If different camera id selected, reset stream retries
                    case WebRtcMessageCaller.CHECK_IF_STREAM_RESOLVED:
                        if (dispatcherWebRTCManager.streamRetryRequests === 1 && dispatcherWebRTCManager.previousCameraId !== message.id) {
                            dispatcherWebRTCManager.streamRetryRequests = 0;
                            dispatcherWebRTCManager.previousCameraId = message.id;
                        } else {
                            dispatcherWebRTCManager.previousCameraId = message.id;
                        }
                        break;

                    case WebRtcMessageCaller.CALLER_MUTED_MIC:
                        muteCallerMicrophoneDispatcherDispatch();
                        break;

                    case WebRtcMessageCaller.CALLER_UNMUTED_MIC:
                        unmuteCallerMicrophoneDispatcherDispatch();
                        break;

                    case WebRtcMessageCaller.CALLER_LOADED_PAGE:
                        if (!isOnStartPage()) {
                            dispatchCallerPageLoaded();
                            const confirmationMessage = {
                                data: WebRtcMessageDispatcher.RECEIVE_CALLER_PAGE_LOADED,
                            };

                            sendWebRtcMessage(confirmationMessage);
                        }

                        if (reduxStore.getState().application.videoIsActive) {
                            deactivateVideoDispatcherDispatch();
                            if (reduxStore.getState().application.drawIsActive) {
                                deactivateDrawDispatcherDispatch();
                            }
                            addNotificationAndShowDispatch('VIDEO STREAM LOST', 'info'); // TODO translate properly
                        }
                        if (reduxStore.getState().application.chatIsActive) {
                            activateChatDispatcherDispatch();
                        } else {
                            if (message.application.chatIsActive) {
                                sendToggleChat(false);
                            }
                        }
                        if (reduxStore.getState().application.audioStreamIsActive) {
                            deactivateAudioStreamDispatcherDispatch();
                            addNotificationAndShowDispatch('info.aud_lst', 'info', DISPLAY_ONLY_IN_SESSION);
                        }

                        if (reduxStore.getState().features.streamRecordingFeature || reduxStore.getState().features.fileShareFeature) {
                            const timeToLiveSettingMessage = {
                                data: WebRtcMessageDispatcher.TIME_TO_LIVE_SETTING,
                                timeToLive: reduxStore.getState().session.timeToLive,
                            };
                            sendWebRtcMessage(timeToLiveSettingMessage);
                        }

                        if (reduxStore.getState().features.fileShareFeature) {
                            if (reduxStore.getState().files.dispatcherFiles.length !== 0 || reduxStore.getState().files.callerFiles.length !== 0) {
                                const uploadedFileContentsMessage = {
                                    data: WebRtcMessageDispatcher.UPLOADED_FILES,
                                    uploadedFiles: reduxStore.getState().files,
                                };

                                sendWebRtcMessage(uploadedFileContentsMessage);
                            }
                        }

                        if (reduxStore.getState().application.externalStreamIsActive) {
                            sendToggleExternalStreaming(true);
                        }

                        // if caller reloads their page, send current feature focus state
                        sendCurrentFocusFeatureStatus();
                        break;

                    case WebRtcMessageCaller.CALLER_DISCLAIMER_VISIBLE:
                        setTimeout(() => {
                            createKpiLog('displayCallerDisclaimer');
                        }, 1000);
                        addNotificationAndShowDispatch('disclaimer.waiting.caller', 'pending', DISPLAY_ONLY_IN_SESSION);
                        break;

                    case WebRtcMessageCaller.CALLER_UPLOADED_FILE: {
                        // send file transfer info to backend
                        sendFileTransferInfo(message.fileInfo, reduxStore.getState().session.timeToLive);
                        // format file size for redux store and display in chat history
                        let file = message.fileInfo;
                        const formattedFileSize = formatDataSize(file.size);
                        file = { ...file, size: formattedFileSize };
                        addFileCallerDispatch(file);
                        dispatchAddSessionFile(file.name, getFileTypeCategory(file), file.time, file.url);
                        createKpiLog('fileTransmissionCallerInfo', '', { 0: message.fileInfo.name, 1: message.fileInfo.size });
                        break;
                    }

                    case WebRtcMessageCaller.TOGGLE_FEATURE_FOCUS:
                        switch (message.state) {
                            case FOCUS_FEATURE_TYPE.LIVE_VIDEO:
                                dispatchSetFocusWindowLiveVideo();
                                break;
                            case FOCUS_FEATURE_TYPE.CHAT:
                                activateChatDispatcherDispatch();
                                dispatchSetFocusWindowChat(WebRtcManagerType.DISPATCHER);
                                break;
                            case FOCUS_FEATURE_TYPE.BIDI:
                                dispatchSetFocusWindowBidi();
                                break;
                            case FOCUS_FEATURE_TYPE.SCREEN_SHARE:
                                dispatchSetFocusWindowScreenshare();
                                break;
                            case FOCUS_FEATURE_TYPE.EXTERNAL_STREAM:
                                dispatchSetFocusWindowExternalStream();
                                break;
                            default:
                                break;
                        }
                        dispatchSetFocusTriggeredByCaller();
                        runAfterTimeHasElapsed(dispatchUnsetFocusChangedByCaller, FOCUS_FEATURE_TIMEOUT);
                        break;

                    case WebRtcMessageCaller.USER_CONNECTION_QUALITY:
                        dispatchAddConnectionQuality(message);
                        break;

                    case WebRtcMessageCaller.PAGE_IN_FOCUS:
                        setCallerTabInFocusDispatch();
                        clearTimeout(dispatcherWebRTCManager.sessionHijackingActivationTimeout);
                        if (reduxStore.getState().sessionHandlingSlice.sessionHijackingIsActive) store.dispatch(deactivateSessionHijacking());
                        break;

                    case WebRtcMessageCaller.PAGE_NOT_IN_FOCUS:
                        setCallerTabNotInFocusDispatch();
                        dispatcherWebRTCManager.sessionHijackingActivationTimeout = setTimeout(() => {
                            store.dispatch(activateSessionHijacking());
                        }, SESSION_HIJACKING_EXPIRATION_TIMEOUT);
                        break;

                    case WebRtcMessageCaller.REQUEST_JOIN_PERMISSION:
                        handleCallerJoined({ sender, message });
                        break;

                    case WebRtcMessageCaller.IS_HEAD_MOUNTED_DEVICE: {
                        const handleHmdDeviceDetected = () => {
                            store.dispatch(setIsHeadMountedDisplayDevice());
                            if (sender.userData.id) {
                                store.dispatch(
                                    updateKnownSmartConnectDevice({
                                        id: sender.userData.id,
                                        type: SmartConnectDeviceType.HMD,
                                    })
                                );
                            }
                            addNotificationAndShowDispatch('session.device.isHmd', 'info', DISPLAY_ONLY_IN_SESSION);
                            createKpiLog('hmdDeviceDetected');
                        };

                        const { sessionHandoverIsActive, knownSmartConnectDevices } = reduxStore.getState().sessionHandlingSlice;

                        // We want to detect a HMD device only if Smart Connect is active
                        // or if the device is already known
                        // all the other times we assume it is a smartphone to prevent false positives
                        // https://mdeg.easyredmine.com/issues/7108?journals=all#note-44200

                        // Check if Smart Connect is active
                        if (sessionHandoverIsActive) {
                            handleHmdDeviceDetected();
                        } else {
                            // Smart Connect is not active, check if the device is already known
                            const device = knownSmartConnectDevices.find(device => device.id === sender.userData.id);
                            if (device?.type === SmartConnectDeviceType.HMD) {
                                handleHmdDeviceDetected();
                            } else {
                                // if it is not known at this point
                                // we assume it is a smartphone
                                if (sender.userData.id) {
                                    store.dispatch(
                                        updateKnownSmartConnectDevice({
                                            id: sender.userData.id,
                                            type: SmartConnectDeviceType.SMARTPHONE,
                                        })
                                    );
                                }
                            }
                        }
                        break;
                    }

                    default:
                        break;
                }
            } else {
                // Reject joining caller device
                const callerDevice = {
                    deviceId: message.deviceId.toString(),
                    communicationUserId: sender.communicationUserId,
                };
                const privateChatThreadId = await createNewChatThread(callerDevice);
                await sendPrivateMessage({ type: WebRtcMessageDispatcher.DISPATCHER_LEFT }, privateChatThreadId);
            }
        }

        /**
         * INCOMING INVITED USER MESSAGES
         **/

        if (
            sender &&
            sender.userData &&
            sender.userData.username &&
            sender.userData.username !== dispatcherAuthSessionId &&
            reduxStore.getState().sessionHandlingSlice.activeDeviceId !== null
        ) {
            switch (message.data) {
                case WebRtcMessageConference.REQUEST_JOIN_PERMISSION:
                    if (reduxStore.getState().invitedUsers.length < MAX_ALLOWED_CONFERENCE_USERS) {
                        const serializeUser = serializeContact(e.sender);
                        dispatchAddInvitedUser(serializeUser.contact);

                        const responseMessage = {
                            data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED,
                        };

                        dispatcherWebRTCManager.sendMessageToAllConferenceUsers(responseMessage);
                        sendSessionData();
                        sendAudioActivationStatusFromStore();
                        sendApplicationData();
                        if (reduxStore.getState().paint.isPaintingAllowed) {
                            sendAllowPaintingToConferenceUsers(true);
                        }
                    } else {
                        const responseMessage = {
                            data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_DECLINED,
                        };
                        dispatcherWebRTCManager.sendMessageToAllConferenceUsers(responseMessage);
                    }
                    break;

                case WebRtcMessageConference.IS_LEAVING:
                    reduxStore.getState().invitedUsers.map(user => {
                        if (sender.userData.id === user.userData.id) {
                            dispatchSetIsLeaving({ userId: user.userData.id });
                        }
                        return null;
                    });
                    break;

                case WebRtcMessageConference.MICROPHONE_ERROR:
                    dispatchSetAudioError(message.userId);
                    break;

                case WebRtcMessageConference.MICROPHONE_IS_MUTED:
                    dispatchToggleAudioMuted({ userId: message.userId, isAudioMuted: true });
                    break;

                default:
                    break;
            }
        }
    }
};
