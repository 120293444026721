import { createListenerMiddleware } from '@reduxjs/toolkit';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { CallClient } from '@azure/communication-calling';
import store from '../store';
import { setWebRtcToken } from '../slices/webRtc/authSlice';
import { setRoomId } from '../slices/webRtc/roomSlice';
import { setChatThreadId, setChatThreadIsConnected, setPrivateChatThreadId } from '../slices/webRtc/chatThreadSlice';
import { ChatClient } from '@azure/communication-chat';
import { addComplexObject, getComplexObject } from '../../helper/complexObjectStorage';
import { UserTypeKeys, WebRtcObjectKeys } from '../../types/webRtc.types';
import { loadMessageEventHandlingDispatcher, saveSessionCredential } from '../../webrtc/events/dispatcherIncomingEvents';
import { loadMessageEventHandlingCaller, validateAndStoreSession } from '../../webrtc/events/callerIncomingEvents';
import { addSessionDevice } from '../slices/sessionHandlingSlice';

const webRtcListenerMiddleware = createListenerMiddleware();

webRtcListenerMiddleware.startListening({
    actionCreator: setWebRtcToken,
    effect: async action => {
        if (action.payload !== null) {
            const token = action.payload;
            if (token) {
                if (store.getState().authSessionSlice.userType === UserTypeKeys.DISPATCHER) {
                    saveSessionCredential(token);
                }

                if (store.getState().authSessionSlice.userType === UserTypeKeys.CALLER) {
                    validateAndStoreSession(token);
                }
            }
        }
    },
});

webRtcListenerMiddleware.startListening({
    actionCreator: setRoomId,
    effect: async action => {
        const roomId = action.payload;
        const token = store.getState().webRtc.authSlice.token;

        if (roomId && token) {
            const credential = new AzureCommunicationTokenCredential(token);
            const callClient = new CallClient();
            const callAgent = await callClient.createCallAgent(credential);

            if (credential && callClient && callAgent) {
                // save the call client and call agent
                addComplexObject(WebRtcObjectKeys.CALL_CLIENT, callClient);
                addComplexObject(WebRtcObjectKeys.CALL_AGENT, callAgent);
            }
        }
    },
});

webRtcListenerMiddleware.startListening({
    actionCreator: setChatThreadId,
    effect: async action => {
        const chatThreadId = action.payload;
        const token = store.getState().webRtc.authSlice.token;

        if (chatThreadId !== null && token && token !== null) {
            const credential = new AzureCommunicationTokenCredential(token);
            const chatClient = new ChatClient(process.env.REACT_APP_ACS_ENDPOINT, credential);
            const chatThreadClient = chatClient.getChatThreadClient(chatThreadId);

            if (chatClient) {
                // save the chat client
                addComplexObject(WebRtcObjectKeys.CHAT_CLIENT, chatClient);

                // join chat thread
                await chatClient.startRealtimeNotifications();
                store.dispatch(setChatThreadIsConnected(true));

                // start chat client event listeners
                if (store.getState().authSessionSlice.userType === UserTypeKeys.DISPATCHER) {
                    loadMessageEventHandlingDispatcher(chatClient);
                } else if (store.getState().authSessionSlice.userType === UserTypeKeys.CALLER) {
                    loadMessageEventHandlingCaller(chatClient);
                }
            }

            if (chatThreadClient) {
                // save the chat client thread client
                addComplexObject(WebRtcObjectKeys.CHAT_THREAD_CLIENT, chatThreadClient);
            }
        }
    },
});

webRtcListenerMiddleware.startListening({
    actionCreator: addSessionDevice,
    effect: async action => {
        const privateChatThreadId = action.payload.privateChatThreadId;

        if (privateChatThreadId) {
            const chatClient = getComplexObject(WebRtcObjectKeys.CHAT_CLIENT);
            const chatThreadClient = chatClient.getChatThreadClient(privateChatThreadId);
        }
    },
});

webRtcListenerMiddleware.startListening({
    actionCreator: setPrivateChatThreadId,
    effect: async action => {
        const privateChatThreadId = action.payload;

        if (privateChatThreadId) {
            const chatClient = getComplexObject(WebRtcObjectKeys.CHAT_CLIENT);
            const chatThreadClient = chatClient.getChatThreadClient(privateChatThreadId);

            if (chatThreadClient) {
                addComplexObject(privateChatThreadId, chatThreadClient);
            }
        }
    },
});

export default webRtcListenerMiddleware;
