import { getURLParams } from '../../helper/rtcFlowHandling';
import {
    ADD_CONNECTION_QUALITY,
    ADD_INVITED_USER,
    ADD_STREAM,
    REMOVE_INVITED_USER,
    REMOVE_STREAM,
    RESET_INVITED_USERS,
    SET_AUDIO_ERROR,
    SET_IS_LEAVING,
    TOGGLE_AUDIO_MUTED,
} from '../actions/invitedUsers';

export const initialState = [];

type InvitedUsersAction = {
    type: string;
    payload: any;
};

const invitedUsersReducer = (state = initialState, action: InvitedUsersAction) => {
    switch (action.type) {
        case ADD_INVITED_USER: {
            const { dispatcherAuthSessionId } = getURLParams();
            const userExists = state.some(user => user.userData.username === action.payload.userData.username);
            const userIsCaller = dispatcherAuthSessionId === action.payload.userData.username;
            const userIsDispatcher = 'dispatcher' === action.payload.userData.username;

            if (!userExists && !userIsCaller && !userIsDispatcher) {
                return [...state, action.payload];
            }
            return state;
        }
        case REMOVE_INVITED_USER:
            return state.filter(user => user.userData.username !== action.payload.userData.username);
        case ADD_STREAM:
            return state.map(user => {
                if (user.userData.username === action.payload.contact.userData.username) {
                    return {
                        ...user,
                        streams: [...user.streams, action.payload],
                    };
                }
                return user;
            });
        case REMOVE_STREAM:
            return state.map(user => {
                if (user.userData.username === action.payload.contact.userData.username) {
                    try {
                        return {
                            ...user,
                            streams: user.streams.filter(stream => stream.streamId !== action.payload.streamId),
                        };
                    } catch {
                        console.log('No stream array found');
                    }
                }
                return user;
            });
        case ADD_CONNECTION_QUALITY:
            return state.map(user => {
                if (user.userData.id === action.payload.userId) {
                    return {
                        ...user,
                        connectionQuality: action.payload.upload,
                    };
                }
                return user;
            });
        case TOGGLE_AUDIO_MUTED:
            return state.map(user => {
                if (user.userData.id === action.payload.userId) {
                    return {
                        ...user,
                        isAudioMuted: action.payload.isAudioMuted,
                    };
                }
                return user;
            });
        case SET_AUDIO_ERROR:
            return state.map(user => {
                if (user.userData.id === action.payload) {
                    return {
                        ...user,
                        hasAudioError: true,
                    };
                }
                return user;
            });
        case SET_IS_LEAVING:
            return state.map(user => {
                if (user.userData.id === action.payload.userId) {
                    return {
                        ...user,
                        isLeaving: true,
                    };
                }
                return user;
            });
        case RESET_INVITED_USERS:
            return initialState;
        default:
            return state;
    }
};

export default invitedUsersReducer;
