import { DEBUG } from '../../config';
import { addComplexObject, getComplexObject } from '../../helper/complexObjectStorage';
import { createKpiLog } from '../../helper/helper';
import { getURLParams } from '../../helper/rtcFlowHandling';
import { connectionEstablishedDispatch } from '../../redux/actions/connection';
import store from '../../redux/store';
import { UserTypeKeys, WebRtcObjectKeys, WebRtcUser } from '../../types/webRtc.types';

export const establishConnectivityWithCallerDevice = () => {
    createKpiLog('infoConnectionEstablished');
    connectionEstablishedDispatch();
};

export const createNewChatThread = async (webRtcUser: WebRtcUser) => {
    const chatClient = getComplexObject(WebRtcObjectKeys.CHAT_CLIENT);
    const addParticipantsAndCreateThread = async () => {
        const privateThread = await chatClient.createChatThread(
            {
                topic: webRtcUser.communicationUserId,
            },
            {
                participants: [
                    { id: { communicationUserId: webRtcUser.communicationUserId }, displayName: webRtcUser.communicationUserId },
                    {
                        id: { communicationUserId: store.getState().webRtc.authSlice.communicationUserId },
                        displayName: UserTypeKeys.DISPATCHER,
                    },
                ],
            }
        );

        const privateChatThreadClient = await chatClient.getChatThreadClient(privateThread.chatThread.id);
        addComplexObject(privateThread.chatThread.id, privateChatThreadClient);
        return privateThread.chatThread.id;
    };

    if (chatClient) {
        return await addParticipantsAndCreateThread();
    }
};

const sendMessageToChatThread = async (msg: string, data) => {
    const eexMessage = { data, message: msg };
    const message = { content: JSON.stringify(eexMessage) };
    const options = { type: 'text' };
    const chatThreadClient = getComplexObject(WebRtcObjectKeys.CHAT_THREAD_CLIENT);
    const result = await chatThreadClient.sendMessage(message, options);

    if (DEBUG) {
        console.log('sendMessageToChatThread: ', result.id, message);
    }
};

export const sendPrivateMessage = async (data, chatThreadId) => {
    const { webRtcSessionId } = getURLParams();
    const message = {
        content: JSON.stringify({ user: UserTypeKeys.DISPATCHER, deviceId: store.getState().authSessionSlice.authSessionId, webRtcSessionId, ...data }),
    };
    const options = { type: 'text' };
    const privateChatThreadClient = getComplexObject(chatThreadId);
    if (privateChatThreadClient) {
        const result = await privateChatThreadClient.sendMessage(message, options);

        if (DEBUG) {
            console.log('sendPrivateMessage: ', result.id, JSON.parse(message.content));
        }
        return result;
    }

};
