import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatMessage, Device, SmartConnectKnownDevice } from '../../types/sessionHandling.types';
import { CallerDevice, Message, WebRtcUser } from '../../types/webRtc.types';
import { addComplexObject } from '../../helper/complexObjectStorage';

interface SessionHandlingState {
    sessionHandoverIsActive: boolean;
    sessionHandoverSuccessShown: boolean;
    sessionHijackingIsActive: boolean;
    isHeadMountedDisplayDevice: boolean;
    activeDeviceId: string | null;
    devices: Array<CallerDevice>;
    knownSmartConnectDevices: Array<SmartConnectKnownDevice>;
}

// Initial state
const initialState: SessionHandlingState = {
    sessionHandoverIsActive: false,
    sessionHandoverSuccessShown: false,
    sessionHijackingIsActive: false,
    isHeadMountedDisplayDevice: false,
    activeDeviceId: null,
    knownSmartConnectDevices: [],
    devices: [],
};

const sessionHandlingSlice = createSlice({
    name: 'sessionHandling',
    initialState,
    reducers: {
        addSessionDevice: (state, action) => {
            state.devices.push({ ...action.payload, chatHistory: [], geolocation: {}, systemInfo: {} });
        },
        removeSessionDevice: (state, action) => {
            state.devices = state.devices.filter(device => device.deviceId !== action.payload);
        },
        replaceCallerDevice: (state, action: PayloadAction<{ currentDevice: CallerDevice; newDevice: WebRtcUser }>) => {
            const { currentDevice, newDevice } = action.payload;
            state.devices = state.devices.map(device => {
                if (device.deviceId === currentDevice.deviceId) {
                    return { ...device, communicationUserId: newDevice.communicationUserId };
                }
                return device;
            });
        },
        addDeviceSystemInfo: (state, action) => {
            const { activeDeviceId } = action.payload;
            const activeDevice = state.devices.find(device => device.deviceId === activeDeviceId);
            if (activeDevice) {
                activeDevice.systemInfo = {
                    browser: action.payload.browser,
                    osVersion: action.payload.osVersion,
                    osName: action.payload.osName,
                };
            }
        },
        addBatteryInfo: (state, action) => {
            const { activeDeviceId } = action.payload;
            const activeDevice = state.devices.find(device => device.deviceId === activeDeviceId);
            if (activeDevice) {
                activeDevice.systemInfo = {
                    ...activeDevice.systemInfo,
                    battery: action.payload.battery,
                };
            }
        },
        activateSessionHandover: state => {
            state.sessionHandoverIsActive = true;
        },
        deactivateSessionHandover: state => {
            state.sessionHandoverIsActive = false;
        },
        setIsHeadMountedDisplayDevice: state => {
            state.isHeadMountedDisplayDevice = true;
        },
        unsetIsHeadMountedDisplayDevice: state => {
            state.isHeadMountedDisplayDevice = false;
        },
        updateKnownSmartConnectDevice: (state, action: PayloadAction<SmartConnectKnownDevice>) => {
            // check if device is already known, if so, update it
            // else add it to the known devices
            const deviceIndex = state.knownSmartConnectDevices.findIndex(device => device.id === action.payload.id);
            if (deviceIndex !== -1) {
                state.knownSmartConnectDevices[deviceIndex] = action.payload;
            } else {
                state.knownSmartConnectDevices.push(action.payload);
            }
        },
        updateActiveDeviceId: (state, action) => {
            const callerDevice = action.payload;
            state.activeDeviceId = callerDevice.deviceId.toString();
        },
        showSessionHandoverSuccess: state => {
            state.sessionHandoverSuccessShown = true;
        },
        hideSessionHandoverSuccess: state => {
            state.sessionHandoverSuccessShown = false;
        },
        activateSessionHijacking: state => {
            state.sessionHijackingIsActive = true;
        },
        deactivateSessionHijacking: state => {
            state.sessionHijackingIsActive = false;
        },
        addChatHistory: (state, action: PayloadAction<{ activeDeviceId: string; dispatcherMessage: ChatMessage }>) => {
            const { activeDeviceId, dispatcherMessage } = action.payload;
            const activeDevice = state.devices.find(device => device.deviceId === activeDeviceId);
            if (activeDevice) {
                (activeDevice.chatHistory as ChatMessage[]).push(dispatcherMessage);
            }
        },
        // TODO: add type for geolocation
        addGeoLocation: (state, action: PayloadAction<{ activeDeviceId: string; geolocation }>) => {
            const { activeDeviceId, geolocation } = action.payload;
            const activeDevice = state.devices.find(device => device.deviceId === activeDeviceId);
            if (activeDevice) {
                activeDevice.geolocation = geolocation;
            }
        },
        addAllDevices: (state, action: PayloadAction<Array<CallerDevice>>) => {
            state.devices = action.payload;
        },
        resetSessionHandlingData: () => initialState,
    },
});

export const {
    addSessionDevice,
    removeSessionDevice,
    replaceCallerDevice,
    addDeviceSystemInfo,
    addBatteryInfo,
    activateSessionHandover,
    deactivateSessionHandover,
    setIsHeadMountedDisplayDevice,
    unsetIsHeadMountedDisplayDevice,
    updateActiveDeviceId,
    updateKnownSmartConnectDevice,
    showSessionHandoverSuccess,
    hideSessionHandoverSuccess,
    activateSessionHijacking,
    deactivateSessionHijacking,
    addChatHistory,
    addGeoLocation,
    addAllDevices,
    resetSessionHandlingData,
} = sessionHandlingSlice.actions;

export default sessionHandlingSlice.reducer;
