import { ChatClient } from '@azure/communication-chat';
import { addComplexObject, getComplexObject } from '../../helper/complexObjectStorage';
import { UserTypeKeys, WebRtcObjectKeys } from '../../types/webRtc.types';
import { getURLParams } from '../../helper/rtcFlowHandling';
import store from '../../redux/store';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { generateSixDigitRandomNumber } from '../../helper/helper';
import { WebRtcMessageCaller } from '../../types';
import { handleContactMessageCaller } from '../incomingMessages/handleContactMessageCaller';
import { setAuthSessionId } from '../../redux/slices/authSessionSlice';
import { sendMessageToChatThread } from '../actions/callerWebRtcActions';
import { DEBUG } from '../../config';

// event loader
export const loadMessageEventHandlingCaller = (chatClient: ChatClient) => {
    chatClient.on('chatMessageReceived', async e => {
        handleContactMessageCaller(e);
    });
    chatClient.on('realTimeNotificationConnected', () => {
        if (DEBUG) {
            console.log('realTimeNotificationConnected');
        }

        // send join request
        sendMessageToChatThread({ type: WebRtcMessageCaller.REQUEST_JOIN_PERMISSION });
    });
    chatClient.on('realTimeNotificationDisconnected', () => {
        if (DEBUG) {
            console.log('realTimeNotificationDisconnected');
        }
    });
};

export const validateAndStoreSession = (token: string) => {
    const { webRtcSessionId } = getURLParams();
    const credential = new AzureCommunicationTokenCredential(token);
    const storedSession = sessionStorage.getItem(webRtcSessionId);

    if (credential) {
        if (storedSession) {
            const authSessionId = JSON.parse(storedSession).deviceId;
            store.dispatch(setAuthSessionId(authSessionId));
        } else {
            // generate 6 digit authSessionId - persists while tab is opened
            const authSessionId = generateSixDigitRandomNumber();
            sessionStorage.setItem(webRtcSessionId, JSON.stringify({ webRtcSessionId, deviceId: authSessionId }));
            store.dispatch(setAuthSessionId(authSessionId));
        }
        addComplexObject(WebRtcObjectKeys.TOKEN_CREDENTIAL, credential);
    }
};
