import { convertImageToFormat, uploadImage } from '../../api/backendApi';
import { ALLOW_IMAGE_CONVERSION, DISPLAY_ONLY_IN_SESSION, IMAGE_TARGET, IMAGE_TYPE_ENDINGS } from '../../config';
import { createKpiLog, obscurePhonenumber, replaceText } from '../../helper/helper';
import store from '../store/index';
import {
    ADD_SESSION_LOG,
    RESET_SESSION,
    START_SESSION,
    STOP_SESSION,
    ADD_SESSION_CHATMESSAGE,
    ADD_SESSION_IMAGE,
    ADD_SESSION_MAP,
    ADD_SESSION_INFOS,
    ADD_SESSION_GPS,
    DISABLE_SESSION_DOWNLOAD,
    ADD_SESSION_LANGUAGE,
    ADD_SESSION_WHITELABEL_LOGO,
    CHANGE_SESSION_IMAGE_FORMAT,
    ALLOW_PHOTO_PERMISSION,
    DISALLOW_PHOTO_PERMISSION,
    ADD_SESSION_NOTES,
    ADD_SESSION_RECORDING,
    ALLOW_STREAM_RECORDING_PERMISSION,
    DISALLOW_STREAM_RECORDING_PERMISSION,
    UPDATE_SESSION_PHONENUMBER,
    ADD_VIDEO_BACKGROUND_IMAGE,
    REMOVE_VIDEO_BACKGROUND_IMAGE,
    ADD_TTL,
    ADD_SESSION_FILE,
    ADD_SESSION_CHAT_HISTORY,
    ADD_CALLER_CONNECTION_QUALITY,
} from './actionTypes';
import { addNotificationAndShowDispatch } from './notifications';

/**
 * All session actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const startSession = ({ start, userId, bystanderToken, dispatchCenter, user, phone }) => {
    return {
        type: START_SESSION,
        start,
        userId,
        bystanderToken,
        dispatchCenter,
        user,
        phone,
    };
};

export const startSessionDispatch = ({ userId, bystanderToken, dispatchCenter, user, phone }) => {
    const start = new Date().getTime();
    const callerDisclaimerFeatureIsActive = store.getState().features.disclaimerFeatureCaller;
    const callerDisclaimerAllowed =
        store.getState().disclaimers.usageDisclaimerStateCaller === 'accepted' || store.getState().disclaimers.usageDisclaimerStateCaller === 'none';

    let phoneNumberIsNotObscured = false;

    if (callerDisclaimerFeatureIsActive) {
        phoneNumberIsNotObscured = callerDisclaimerAllowed;
    } else {
        phoneNumberIsNotObscured = true;
    }

    const phoneNumber = phoneNumberIsNotObscured ? phone : obscurePhonenumber(phone);

    // Below variable userId and bystanderToken are used strictly for data structure in pdf session report
    store.dispatch(
        startSession({
            start,
            userId,
            bystanderToken,
            dispatchCenter,
            user,
            phone: phoneNumber,
        })
    );
};

const stopSession = ({ end }) => {
    return {
        type: STOP_SESSION,
        end,
    };
};

export const stopSessionDispatch = () => {
    const end = new Date().getTime();
    store.dispatch(stopSession({ end }));
};

const addSessionLogEntry = ({ message, eventId, additionalInformation, timestamp }) => {
    const entry = {
        message,
        eventId,
        additionalInformation,
        timestamp,
    };
    return {
        type: ADD_SESSION_LOG,
        entry,
    };
};

export const addSessionLogEntryDispatch = ({ message, eventId, additionalInformation }) => {
    const timestamp = new Date().getTime();
    store.dispatch(
        addSessionLogEntry({
            message,
            eventId,
            additionalInformation,
            timestamp,
        })
    );
};

const resetSession = () => {
    return { type: RESET_SESSION };
};

export const resetSessionDispatch = () => {
    store.dispatch(resetSession());
};

export const resetAndStartSession = ({ userId, bystanderToken, dispatchCenter, user, phone }) => {
    resetSessionDispatch();
    startSessionDispatch({
        userId,
        bystanderToken,
        dispatchCenter,
        user,
        phone,
    });
};

const addSessionChatMessage = ({ entry }) => {
    return { type: ADD_SESSION_CHATMESSAGE, entry };
};

export const addSessionChatMessageDispatch = ({ message, translation, from, timestamp }) => {
    const entry = {
        message,
        translation,
        from,
        timestamp,
    };
    store.dispatch(addSessionChatMessage({ entry }));
};

const addSessionChatHistory = history => {
    return { type: ADD_SESSION_CHAT_HISTORY, history };
};

export const dispatchAddSessionChatHistory = history => {
    store.dispatch(addSessionChatHistory(history));
};

const addSessionImage = image => {
    return {
        type: ADD_SESSION_IMAGE,
        image,
    };
};

const updatePhonenumber = phone => {
    return {
        type: UPDATE_SESSION_PHONENUMBER,
        phone,
    };
};

export const updatePhonenumberDispatch = phone => {
    store.dispatch(updatePhonenumber(phone));
};

/**
 * adds the imageObject to the session store
 * if image conversion is allowed it converts the given image to the target format
 * @param {object} imageObject
 */
export const addSessionImageDispatch = async imageObject => {
    if (ALLOW_IMAGE_CONVERSION) {
        const imageFormat = store.getState().session.imageFormat;
        const convert = async image => {
            const targetFormat = imageFormat.toLocaleLowerCase();

            if (IMAGE_TYPE_ENDINGS[image.type] === IMAGE_TARGET[targetFormat]) {
                return image;
            }
            return await convertImageToFormat(image, targetFormat);
        };

        const convertedImage = await convert(imageObject);

        store.dispatch(addSessionImage(convertedImage));

        // upload this image to the backend
        uploadImage({ ...convertedImage });
    } else {
        store.dispatch(addSessionImage(imageObject));

        // upload this image to the backend
        uploadImage({ ...imageObject });
    }
};

const addSessionMap = ({ map }) => {
    return {
        type: ADD_SESSION_MAP,
        map,
    };
};

export const addSessionMapDispatch = ({ map }) => {
    store.dispatch(addSessionMap({ map }));
};

const addSessionInfos = ({ osName, osVersion, browser }) => {
    return {
        type: ADD_SESSION_INFOS,
        osName,
        osVersion,
        browser,
    };
};

export const addSessionInfosDispatch = ({ osName, osVersion, browser }) => {
    store.dispatch(addSessionInfos({ osName, osVersion, browser }));
};

const addSessionGPS = ({ long, lat, accuracy }) => {
    return {
        type: ADD_SESSION_GPS,
        long,
        lat,
        accuracy,
    };
};

export const addSessionGPSDispatch = ({ long, lat, accuracy }) => {
    store.dispatch(addSessionGPS({ long, lat, accuracy }));
};

const disableSessionDownload = () => {
    return {
        type: DISABLE_SESSION_DOWNLOAD,
    };
};

export const disableSessionDownloadDispatch = () => {
    store.dispatch(disableSessionDownload());
};

const addSessionLanguage = ({ lang }) => {
    return {
        type: ADD_SESSION_LANGUAGE,
        lang,
    };
};

export const dispatchAddSessionLanguage = ({ lang }) => {
    store.dispatch(addSessionLanguage({ lang }));
};

const addSessionNotes = ({ notes }) => {
    return {
        type: ADD_SESSION_NOTES,
        notes,
    };
};

export const dispatchAddSessionRecording = ({ recordingInfo, image = '', status }) => {
    store.dispatch(addSessionRecording(recordingInfo, image, status));
};

const addSessionRecording = (recordingInfo, image, status) => {
    return {
        type: ADD_SESSION_RECORDING,
        recordingInfo,
        image,
        status,
    };
};

export const dispatchAddSessionNotes = notes => {
    store.dispatch(addSessionNotes({ notes }));
};

const addWhitelabelLogo = ({ logo }) => {
    return {
        type: ADD_SESSION_WHITELABEL_LOGO,
        logo,
    };
};

export const dispatchAddWhitelabelLogo = ({ logo }) => {
    store.dispatch(addWhitelabelLogo({ logo }));
};

const changeImageFormat = imageFormat => {
    return { type: CHANGE_SESSION_IMAGE_FORMAT, imageFormat };
};

export const dispatchChangeImageFormat = imageFormat => {
    store.dispatch(changeImageFormat(imageFormat));
};

const allowPhotoPermission = () => {
    return {
        type: ALLOW_PHOTO_PERMISSION,
    };
};

export const dispatchAllowPhotoPermisison = () => {
    createKpiLog('permissionScreenshot', 'granted');

    store.dispatch(allowPhotoPermission());
};

const disallowPhotoPermission = () => {
    return {
        type: DISALLOW_PHOTO_PERMISSION,
    };
};

export const dispatchDisallowPhotoPermisison = (notifyDispatcher = false) => {
    if (notifyDispatcher) {
        createKpiLog('permissionScreenshot', 'denied');
        addNotificationAndShowDispatch(replaceText(store.getState().texts.texts, 'snapshot.permission.denied'), 'error', DISPLAY_ONLY_IN_SESSION);
    }

    store.dispatch(disallowPhotoPermission());
};

const allowStreamRecordingPermission = () => {
    return {
        type: ALLOW_STREAM_RECORDING_PERMISSION,
    };
};

export const dispatchAllowStreamRecordingPermission = () => {
    // createKpiLog('permissionScreenshot', 'granted');

    store.dispatch(allowStreamRecordingPermission());
};

const disallowStreamRecordingPermission = () => {
    return {
        type: DISALLOW_STREAM_RECORDING_PERMISSION,
    };
};

export const dispatchDisallowStreamRecordingPermission = () => {
    // createKpiLog('permissionScreenshot', 'denied');

    addNotificationAndShowDispatch(replaceText(store.getState().texts.texts, 'recording.permission.denied'), 'error', DISPLAY_ONLY_IN_SESSION);

    store.dispatch(disallowStreamRecordingPermission());
};

export const addVideoBackgroundImage = ({ image }) => {
    return {
        type: ADD_VIDEO_BACKGROUND_IMAGE,
        image,
    };
};

export const dispatchAddVideoBackgroundImage = ({ image }) => {
    store.dispatch(addVideoBackgroundImage({ image }));
};

export const removeVideoBackgroundImage = () => {
    return {
        type: REMOVE_VIDEO_BACKGROUND_IMAGE,
    };
};

export const dispatchRemoveVideoBackgroundImage = () => {
    store.dispatch(removeVideoBackgroundImage());
};

const addDispatchCenterTtl = milliseconds => {
    return {
        milliseconds,
        type: ADD_TTL,
    };
};

export const dispatchAddDispatchCenterTtl = milliseconds => {
    store.dispatch(addDispatchCenterTtl(milliseconds));
};

export const dispatchAddSessionFile = (filename, fileType, created, url) => {
    store.dispatch(addSessionFile(filename, fileType, created, url));
};

const addSessionFile = (filename, fileType, created, url) => {
    return {
        type: ADD_SESSION_FILE,
        filename,
        fileType,
        created,
        url,
    };
};

const addCallerConnectionQuality = ({ rating }) => {
    return {
        rating,
        type: ADD_CALLER_CONNECTION_QUALITY,
    };
};

export const dispatchAddCallerConnectionQuality = ({ rating }) => {
    store.dispatch(addCallerConnectionQuality({ rating }));
};
