import { DEBUG } from '../config';
import { dispatchAddSessionRecording } from '../redux/actions/session';
import { dispatcherWebRTCManager } from '../webrtc/webrtcManagers/DispatcherWebRTCManager';
import {
    deactivateScreenshareDispatch,
} from '../redux/actions/application';
import { addLogDispatch } from '../redux/actions/logs';
import {
    sendScreenshareToggled,
} from '../webrtc/outgoingMessages/outgoingMessagesDispatcher';
import store from '../redux/store';
import { removeDispatcherAudioStream, removeDispatcherStream } from '../redux/actionCreators/actionCreators';

// get URL address and abstract a session id, caller id, and a conversation name
export const getURLParams = () => {
    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
    var preLastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    return {
        webRtcSessionId: lastSegment,
        dispatcherAuthSessionId: preLastSegment,
        conversationName: lastSegment + '' + preLastSegment,
    };
};

// Conference user display name
export const createUserDisplayName = (storeType, userName) => {
    storeType.connectedSession.setUsername(userName);
};

export const enterConversation = (storeType, options = {}) => {
    const { conversationName } = getURLParams();
    storeType.connectedConversation = storeType.connectedSession.getOrCreateConversation(conversationName, options);
    if (DEBUG) addLogDispatch(['getOrCreateConversation: ', conversationName]);
};

// Dispatcher screen share stream event handler
export const dispatcherStreamHandlers = stream => {
    stream.on('trackStopped', event => {
        // Used to detect when user stops screen share with Chrome/Firefox
        dispatcherWebRTCManager.stoppedViaBrowserButton = true;
        deactivateScreenshareDispatch('dispatcher');
        store.dispatch(removeDispatcherStream(stream));
        sendScreenshareToggled(false);
    });
};

export const unpublishStreamAndRemoveFromRedux = (stream, storeType) => {
    if (stream && storeType.connectedConversation) {
        storeType.connectedConversation.unpublish(stream);
        stream.release();
        store.dispatch(removeDispatcherAudioStream(stream));
    }
};

export const fetchVideoAndThumbnailAndDispatch = recordingInfo => {
    fetch(recordingInfo.mediaURL)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            getThumbnailURL(url);
        });

    // Function to get the thumbnail URL
    function getThumbnailURL(videoURL) {
        const video = document.createElement('video');
        video.src = videoURL;
        video.currentTime = 1;

        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');
        video.addEventListener('loadedmetadata', function () {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            video.addEventListener('canplaythrough', function () {
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                setTimeout(function () {
                    const image = canvas.toDataURL();
                    let status = 'available';
                    dispatchAddSessionRecording({ recordingInfo, image, status });
                    return image;
                }, 100); // delay by 100ms to ensure decoding of video frame completes
            });
        });
    }
};