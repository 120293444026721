export enum WebRtcObjectKeys {
    CHAT_CLIENT = 'CHAT_CLIENT',
    CHAT_THREAD_CLIENT = 'CHAT_THREAD_CLIENT',
    CALL = 'CALL',
    CALL_AGENT = 'CALL_AGENT',
    CALL_CLIENT = 'CALL_CLIENT',
    TOKEN_CREDENTIAL = 'TOKEN_CREDENTIAL',
}

export type ParticipantAdded = {
    addedBy: object,
    addedOn: string,
    participantsAdded: [{}],
    threadId: string,
    version: string,
}

export enum UserTypeKeys {
    DISPATCHER = 'DISPATCHER',
    CALLER = 'CALLER',
    CONFERENCE = 'CONFERENCE'
}

export type Message = {
    message: Content;
    sender: Sender;
};

export type Content = {
    data: string | object,
    deviceId: string,
    user: string,
    webRtcSessionId: string,
}

export type Sender = {
    communicationId: string,
    kind: string,
}

export type CallerDevice = {
    deviceId: string;
    communicationUserId: string;
    systemInfo: {};
    chatHistory: [];
    geolocation: {};
};

export type WebRtcUser = {
    deviceId: string;
    communicationUserId: string;
};