import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthSessionState {
    userType: string;
    authSessionId: string | null;
    phone: string | null;
    isPhoneNumberConsumed: boolean;
}

const initialState: AuthSessionState = {
    userType: null,
    authSessionId: null,
    phone: null,
    isPhoneNumberConsumed: false,
};

const authSessionSlice = createSlice({
    name: 'authSession',
    initialState,
    reducers: {
        setUserType(state, action: PayloadAction<any>) {
            state.userType = action.payload;
        },
        setAuthSessionId(state, action: PayloadAction<any>) {
            state.authSessionId = action.payload;
        },
        setPhone(state, action: PayloadAction<any>) {
            state.phone = action.payload;
        },
        setIsPhoneNumberConsumed(state, action: PayloadAction<any>) {
            state.isPhoneNumberConsumed = action.payload;
        },
        resetAuthSession() {
            return initialState;
        },
    },
});

export const { setUserType, setAuthSessionId, setPhone, setIsPhoneNumberConsumed, resetAuthSession } = authSessionSlice.actions;

export default authSessionSlice.reducer;
